import Model, { attr } from '@ember-data/model';

import type { Amount } from 'qonto/react/models/amount';
interface PaymentInitiator {
  resourceId: string; // id of the resource initiating a payment link
  resourceNumber: string; // eg. "INV-101"
  resourceType: string;
}

type PaymentMethod = 'apple_pay' | 'credit_card' | 'bank_transfer' | 'paypal';

interface Settings {
  redirectUrl: string;
  reusable: boolean;
}

interface Item {
  id: string;
  title: string;
  type: string;
  description: string;
  quantity: number;
  measureUnit: string;
  unitPrice: Amount;
  vatRate: string;
}

export default class PaymentLinkModel extends Model {
  @attr declare amount: Amount<string>;
  @attr declare paymentMethod: PaymentMethod;
  @attr declare latestPaymentMethod?: PaymentMethod;
  @attr declare expirationDate: string;
  @attr declare debitorName: string;
  @attr('hash') declare paymentInitiator: PaymentInitiator;
  @attr declare status: 'open' | 'paid' | 'cancelled' | 'expired';
  @attr declare potentialPaymentMethods: PaymentMethod[];
  @attr('hash') declare settings: Settings;
  @attr declare organizationName: string;
  @attr declare name: string;
  @attr declare items: Item[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-link': PaymentLinkModel;
  }
}
